<template>
  <div 
    v-if="alert"
    v-on="$listeners"
    class="rounded border flex items-center flex-col sm:flex-row"
    :class="{
      'bg-blue-400 border-blue-500 text-white': alert.type == 'info',
      'bg-green-400 border-green-500 text-white': alert.type == 'success',
      'bg-red-400 border-red-500 text-white': alert.type == 'error',
      'bg-yellow-500 border-yellow-600 text-white': alert.type == 'warning',
    }"
  >
    <component :is="icon" :size="56" class="m-4 rounded-full p-1 w-14 flex-shrink-0" :class="{
      'bg-blue-300': alert.type == 'info',
      'bg-green-300': alert.type == 'success',
      'bg-red-300': alert.type == 'error',
      'bg-yellow-400': alert.type == 'warning',
    }"/>
    <div class="p-2 py-4">
      <h3 class="text-lg font-medium mb-1" v-text="alert.title"></h3>
      <p class="text-sm" v-html="alert.text"></p>
    </div>
  </div>

</template>

<script>
import CheckCircle from '../icons/CheckCircle.vue'
import ExclamationTriangle from '../icons/ExclamationTriangle.vue'
import InfoCircle from '../icons/InfoCircle.vue'

export default {
  props: {
    alert: Object
  },
  computed: {
    icon() {
      switch(this.alert.type) {
        case 'info':
          return InfoCircle
        case 'success':
          return CheckCircle
        case 'warning': 
        case 'error': 
        default:
          return ExclamationTriangle
      }
    }
  }
}
</script>